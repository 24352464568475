import React from 'react'
import {Helmet} from 'react-helmet'
import Layout from 'layouts/layout'
import Header from 'components/header'

export default () => (
  <Layout>
    <Helmet>
      <title>
        Modern Slavery Statement | Helios Underwriting
      </title>
    </Helmet>
    <main>
      <Header layout="standard" />
      <section className="padding-md">
        <h1 className="h1 lg:u-2/3">
        Modern Slavery Statement
        </h1>
      </section>

      <section className="padding-md lg:u-2/3">

      
        <p>This statement has been published in accordance with the Modern Slavery Act 2015 (‘MSA’). It sets out the steps taken by Helios Underwriting Plc and its subsidiary companies (together referred to as ‘HUW’) to prevent modern slavery, human rights violations and trafficking in its business and supply chains during the year ended 31 December 2023. This is the sixth statement prepared by HUW and we aim to progress and develop our work in this area as appropriate over the coming years.</p>
        <h3 className="h3 mt-20">Our Business</h3>
        <p>The principal activity of HUW is to provide a limited liability investment for its shareholders in the Lloyd’s insurance market and HUW participates in the Lloyd’s insurance market through its participation in a portfolio of Lloyd’s syndicates.</p>
        <h3 className="h3 mt-20">Commitment</h3>
        <p>As a UK business listed on AIM, we accept we have a responsibility and are committed to upholding human rights and will do our utmost to ensure there is no slavery, servitude, forced or compulsory human labour, abuse of power over vulnerable individuals, human trafficking or any other form of exploitation contemplated by the MSA in any part of our business.</p>
        <h3 className="h3 mt-20">Our Principles and Policies</h3>
        <p>HUW adheres to a high standard of ethics, values and corporate social responsibility and these principles underpin our governance procedures and the strategic and management decisions that we make.</p>
        <p>The Board is committed to ensuring the Company’s business remains sustainable, not only from the shareholders’ perspective, but also for the environment, customers, suppliers and others affected by the Company’s activities. The Company has in place a payment policy which is observed and annually reviewed.</p>
        <p>Through its subsidiaries the Company owns a portfolio of underwriting capacity interests at Lloyd’s and, whilst it has no direct responsibility for the management of those businesses, they are managed by  highly regulated managing agents that in turn have responsibility for managing the businesses, their staff and employment policies, and their environmental impact. Therefore, the Board does not consider it appropriate to monitor or report any performance indicators in relation to corporate, social or environmental matters.</p>
        <p>Furthermore, for a managing agent to operate at Lloyd’s there are ‘statements of business conduct’ (‘Lloyd’s Minimum Standards’) and principles for doing business with which the managing agent must comply. These encompass matters such as claims management, treating customers fairly, governance, culture, inclusion, diversity and ESG. The managing agents and senior managers within them are also subject to regulation by the Financial Conduct Authority (‘FCA’) and the Prudential Regulatory Authority (‘PRA’) and must comply with their stringent and regular reporting requirements.  Together, these rules and regulations set a high bar for conduct and how relationships and business are managed.</p>
        <p>On 23 March 2023 the Board approved an Environmental, Social and Governance (ESG) Policy Statement, which will be subject to an annual review by the Board within 18 months. Helios offers investors exposure to a diversified portfolio of syndicates at Lloyd’s of London. As a consequence Helios is inexorably aligned to the approach Lloyd’s takes with regard to the society as a whole in addition to those adopted by the various managing agencies.</p>
        <p>The Chief Executive Officer, Chief Operating Officer and Finance Director are in regular contact with the managing agents not only to review performance of the portfolio, but also to consider governance matters and compliance with Lloyd’s Minimum Standards and principles for doing business.</p>
        <h3 className="h3 mt-20">Assessment of Risk</h3>
        <p>HUW has assessed the relationships that it has with investors based predominately within the UK, together with the highly regulated underwriting sector within which it operates, and concluded that its business and supply chain present minimal opportunity and a very low risk in terms of modern slavery, human rights violations and trafficking. Nevertheless, we will implement remedial measures should any concern be identified.</p>
        <p>Similarly, HUW has no partnerships with businesses based in impoverished regions of the world, where labour laws are non-existent or are not enforced, and therefore the risk in terms of modern slavery, human rights violations and trafficking is considered low. </p>
        <p>Our employment procedures require that all employees have the necessary documentation to legally work and no-one will be employed without providing the necessary evidence. This will help ensure that no one forced, trafficked or under the legal minimum age for admission to work will be employed by HUW.</p>
        <h3 className="h3 mt-20">Due Diligence</h3>
        <p>HUW does not support or deal with any businesses believed to be involved in or linked to modern slavery, human rights violations or trafficking and we will implement remedial measures should any concern about a particular investor or business contact be identified.</p>
        <p>This statement was approved by the Company’s Board of Directors on 29 May 2024.</p>
        <p><span class="u-bold">Martin Reith, Chief Executive Officer</span></p>

      </section>

    </main>
  </Layout>
)
